import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../layouts"
import "../styles/articles.scss"
import 'uikit/dist/css/uikit.css'
import GetImgUrl from "../utils/customFunctions"
import checkPromoValue from "../utils/checkPromoValue"
import { FaFacebook, FaTwitter } from "react-icons/fa"
import loadable from '@loadable/component'
import RichSnippet from "../components/rich-snippet-component/rich-snippet"
import useSiteMetadata from "../utils/useSiteMetadata"
import RelatedArticles from "../components/related-articles/related-articles"
import Reviews from "../components/reviews/reviews"
import ParagraphColumn from "../components/paragraph-column/paragraph-column"
import ProviderCarousel from "../components/provider-carousel/provider-carousel"
import LinkCards from "../components/link-cards/link-cards"
import Related from "../components/related/related"

const Header = loadable(() => import("../components/header/header"))
const Banner = loadable(() => import("../components/banner/banner"))
const Paragraph = loadable(() => import("../components/paragraph/paragraph"))
const Accordion = loadable(() => import("../components/accordion/accordion"))
const CTAList = loadable(() => import("../components/cta-list/ctaList"))
const Providers = loadable(() => import("../components/provider-list/providers"))
const Comparison = loadable(() => import("../components/comparison/comparison"))
const ImageCarousel = loadable(() => import("../components/image-carousel/image-carousel"))
const HtmlEditor = loadable(() => import("../components/html-editor/html-editor"))
const SingleImage = loadable(() => import("../components/single-image/single-image"))
const CTABanner = loadable(() => import("../components/cta-banner/cta-banner"))

export const query = graphql`
  query ArticlesQuery($id: Int) {
    strapiArticles(strapiId: { eq: $id }) {
      strapiId
      Title
      Excerpt
      slug
      Canonical
      HideQuoteButton
      HidePhoneButton
      OverwriteQuoteButtonURL
      OverwritePhoneButtonNumber
      Author
      PageContent
      SEOTitle
      SEODescription
      FooterDisclaimer
      FeaturedImageLayout
      HideBreadcrumbs
      FeaturedImage{
          url
          localFile{
              publicURL
              relativePath
              absolutePath
              id
              childImageSharp {
                gatsbyImageData
              }
          }
      }
      
      blog_category{
        CategoryName
        CategorySlug
      }
     
      published_at(formatString: "MMM Do YYYY")
      PublishedDate(formatString: "MMM Do YYYY")
      MetaTags{
        MetaName
        MetaContent
      }
      Related {
        CTALabel
        CTAUrl
        CTA {
          Label
          URL
          Image {
            url
          }
        }
        Links {
          Title
          URL
        }
      }
    }
    strapiSiteSettings {
     SiteTitle
        LogoDark {
        localFile {
          publicURL
        }
      }
        SocialLinks{
            FacebookURL
            LinkedinURL
            TwitterURL
            YoutubeURL
        }
    }
   
    
  }
`;

function ShareOnSocial(sharePlatform, link) {
    return (window.open(sharePlatform + link, 'popupwindow', 'scrollbars=yes, width=800, height=400'))

}





// markup
const Articles = ({ pageContext, data }) => {



    const { siteUrl: url } = useSiteMetadata()


    let richSnippetDataForArticle = {

        '@context': "https://schema.org",
        "@type": "BlogPost",
        "mainEntityOfPage": {
            "@type": "WebPage",
            "@id": `${url + '/' + pageContext.blog_category_slug + '/' + data.strapiArticles.slug}`
        },
        "headline": `${data.strapiArticles.Title}`,
        "description": `${data.strapiArticles.Excerpt ? data.strapiArticles.Excerpt : data.strapiArticles.SEODescription}`,
        "image": `${url + data.strapiArticles.FeaturedImage?.localFile?.publicURL || ''}`,
        "author": {
            "@type": "Person",
            "name": `${data.strapiArticles.Author}`
        },
        "publisher": {
            "@type": "Organization",
            "name": `${data.strapiSiteSettings.SiteTitle}`,
            "logo": {
                "@type": `${url + data.strapiArticles.FeaturedImage?.localFile.publicURL || ''}`,
                "url": `${url + data.strapiSiteSettings.LogoDark.localFile.publicURL}`
            }
        },
        "datePublished": `${(!data.strapiArticles.PublishedDate) ? data.strapiArticles.published_at : data.strapiArticles.PublishedDate}`

    }



    let breadCrumbsData = {
        '@context': "https://schema.org/",
        "@type": "Breadcrumb",
        itemListElement: [{
            type: "ListItem",
            position: 1,
            name: `${pageContext.blog_category_name}`,
            item: `${url + '/' + pageContext.blog_category_slug}`
        },
        {
            type: "ListItem",
            position: 2,
            name: `${data.strapiArticles.Title}`,
            item: `${url + '/' + pageContext.blog_category_slug + '/' + data.strapiArticles.slug}`
        }]
    }
    var ukGrid = data.strapiArticles.Related ? "uk-grid" : ""

    const numberOfParagraphs = data.strapiArticles.PageContent.filter((item) => item.strapi_component === 'page-components.paragraph')

    const numberOfHeaders = data.strapiArticles.PageContent.filter((item) => item.strapi_component === 'page-components.header')



    return (
        //returns the related layout
        <Layout
            SEOTitle={data.strapiArticles.SEOTitle || data.strapiArticles.Title}
            SEODescription={data.strapiArticles.SEODescription} footerDisclaimer={data.strapiArticles.FooterDisclaimer}
            darkThemeNav={true} meta={data.strapiArticles.MetaTags}
            canonical={data.strapiArticles.Canonical ? data.strapiArticles.Canonical : `${url}/${data.strapiArticles.blog_category.CategorySlug}/${data.strapiArticles.slug}`}
            hideQuoteButton={data.strapiArticles.HideQuoteButton}
            hidePhoneButton={data.strapiArticles.HidePhoneButton}
            overwriteQuoteButtonURL={data.strapiArticles.OverwriteQuoteButtonURL}
            overwritePhoneButtonNumber={data.strapiArticles.OverwritePhoneButtonNumber}
            >

            <RichSnippet type={'BlogPosting'} data={richSnippetDataForArticle} />


            <div className={data.strapiArticles.Related && data.strapiArticles.Related.Links.length >= 0 ? "articles-template secondary-split display-type" : data.strapiArticles.HideBreadcrumbs === false || data.strapiArticles.HideBreadcrumbs === null ? "articles-template secondary-split" : ""}>

                <div className={data.strapiArticles.Related && data.strapiArticles.Related.Links.length >= 0 ? "uk-width-3-5@m content-column" : data.strapiArticles.HideBreadcrumbs === false || data.strapiArticles.HideBreadcrumbs === null ? "content-column" : ""}>

                    {/** Breadcrumbs */}
                    {
                        data.strapiArticles.HideBreadcrumbs === false || data.strapiArticles.HideBreadcrumbs === null ?
                            <div className="breadcrumbs-container">

                                <RichSnippet data={breadCrumbsData} type={'Breadcrumb'} />
                                <Link to="/">Home</Link>
                                {' > '}
                                <Link to={`/${pageContext.blog_category_slug}`}>{pageContext.blog_category_name}</Link>
                                {' > ' + data.strapiArticles.Title}
                            </div>
                            : ""

                    }

                    {/** Featured image */}
                    <div className={data.strapiArticles.FeaturedImageLayout == "default" || data.strapiArticles.FeaturedImageLayout === null ? "article-featured-image-container" : "article-featured-image-display"}>

                        <ImageCarousel img={data.strapiArticles.FeaturedImage?.localFile?.childImageSharp?.gatsbyImageData || ""} />


                    </div>


                    {

                        data.strapiArticles.HideBreadcrumbs === false || data.strapiArticles.HideBreadcrumbs === null ?
                            <div className="article-author">
                                Posted by {data.strapiArticles.Author} on {(!data.strapiArticles.PublishedDate) ? data.strapiArticles.published_at : data.strapiArticles.PublishedDate}
                            </div>
                            : ""
                    }





                </div>

                {
                    data.strapiArticles.Related && data.strapiArticles.Related.Links.length >= 0 ?

                        <div className="uk-width-2-5@m related-col">
                            {
                                (data.strapiArticles.Related && data.strapiArticles.Related.Links.length >= 0) ?
                                    <div className="related-col-title">
                                        Related
                                    </div>
                                    : ""
                            }

                            {/**Related Links 
                            * 
                            * Will show the related links coming from strapi if 
                            * there are any. If not, then will show a list of
                            * articles that belong to the same category
                            * 
                            * 
                            */}

                            <div className="related-links">
                                <ul>
                                    {
                                        (data.strapiArticles.Related && data.strapiArticles.Related.Links.length > 0) ?
                                            data.strapiArticles.Related.Links.slice(0, 5).map((item, index) => {

                                                return (
                                                    data.strapiArticles.Title !== item.Title ?
                                                        <li key={index}>
                                                            <Link to={item.URL}>{item.Title}</Link>
                                                        </li>
                                                        : ""
                                                )


                                            })
                                            :
                                            (data.strapiArticles.Related && data.strapiArticles.Related.Links.length == 0) ?
                                                <Related articleId={data.strapiArticles.strapiId} articles={pageContext.articles} />

                                                : ""
                                    }
                                </ul>
                            </div>

                            {/**Related CTA */}

                            {data.strapiArticles.Related?.CTALabel ? <div className="related-cta">
                                <div class="related__cta">
                                    <a className="cta" href={data.strapiArticles.Related ? data.strapiArticles.Related.CTAUrl : ""}>{data.strapiArticles.Related.CTALabel}</a>
                                </div>
                            </div> : ""}
                            {data.strapiArticles.Related?.CTA[0] ? <div className="related-cta-cards">
                                <ul>
                                    {data.strapiArticles.Related ? data.strapiArticles.Related.CTA.map((item, index) => {

                                        return (
                                            <li key={index}>
                                                <Link to={item.URL} key={index}>
                                                    <img src={item.Image.url}></img>
                                                    {item.Label}

                                                </Link>
                                            </li>
                                        )


                                    }) : ""}
                                </ul>
                            </div> : ""}

                            {/**Related social sharing links */}

                            <div className="related-social">

                                <span>Share:</span>
                                <ul>

                                    <li><a href="#0" onClick={() => ShareOnSocial("http://www.facebook.com/share.php?u=", window.location.href)}><FaFacebook size="1.75rem" /></a></li>

                                    <li><a href="#0" onClick={() => ShareOnSocial("https://twitter.com/intent/tweet?url=", window.location.href)}><FaTwitter size="1.75rem" /></a></li>

                                </ul>
                            </div>
                        </div>


                        : ""
                }


            </div>


            {data.strapiArticles.PageContent.map((item, index) => {


                switch (item.strapi_component) {

                    case "page-components.header":
                        return (
                            <Header key={index}
                                title={item.Title}
                                subtitle={item.Subtitle}
                                secondarySubtitle={item.SecondSubtitle}
                                CTAText={item.CTALabel}
                                CTAUrl={item.CTAUrl}
                                Disclaimer={item.Disclaimer}
                                Background={GetImgUrl(item.Background.localFile___NODE, pageContext.images)}
                                HeaderHeight={item.HeaderHeight}
                                BackgroundColor={item.BackgroundColor}
                                TextAlign={item.TextAlign}
                            ></Header>
                        )

                    case "page-components.paragraph":
                        let title;
                        let forceH1Title = false;

                        if (item.Title) {
                            if (numberOfParagraphs[0].id === item.id && numberOfHeaders.length === 0) {
                                forceH1Title = true;
                            }
                            title = item.Title
                        } else {
                            if (numberOfParagraphs[0].id === item.id && numberOfHeaders.length === 0) {
                                title = data.strapiArticles.Title
                                forceH1Title = true;
                            }

                        }

                        return (
                            <>
                                <div className="articles-template-paragraph-container">
                                    <Paragraph
                                        key={index}
                                        title={title}
                                        text={item.Text}
                                        image={item.Image !== null ? GetImgUrl(item.Image.localFile___NODE, pageContext.images) : null}
                                        imagePosition={item.ImagePosition}
                                        bgColor={item.BackgroundColor || ""}
                                        topPadding={(item.TopPadding === null || item.TopPadding === true) ? true : false}
                                        bottomPadding={(item.BottomPadding === null || item.BottomPadding === true) ? true : false}
                                        forceH1Title={forceH1Title}
                                        alignment={item.ContentAlignment}
                                        initialPadding={item.InitialPadding}
                                    ></Paragraph>
                                </div>
                            </>
                        )

                    case "page-components.image":
                        return (
                            <SingleImage
                                image={item.Image !== null ? GetImgUrl(item.Image.localFile___NODE, pageContext.images).gatsbyImageData : null}
                                altText={item.Image !== null ? item.Image.alternativeText : null}
                                topPadding={(item.TopPadding === null || item.TopPadding === true) ? true : false}
                                bottomPadding={(item.BottomPadding === null || item.BottomPadding === true) ? true : false}
                                imgWidth={item.ImgWidth}
                            ></SingleImage>
                        )

                    case "page-components.comparison":
                        return (
                            <Comparison key={index}
                                title={item.Title}
                                step1={item.Step1}
                                step2={item.Step2}
                                step3={item.Step3}
                                image1={item.Image1 !== null ? GetImgUrl(item.Image1.localFile___NODE, pageContext.images) : null}
                                image2={item.Image2 !== null ? GetImgUrl(item.Image2.localFile___NODE, pageContext.images) : null}
                                image3={item.Image3 !== null ? GetImgUrl(item.Image3.localFile___NODE, pageContext.images) : null}
                                topPadding={(item.TopPadding === null || item.TopPadding === true) ? true : false}
                                bottomPadding={(item.BottomPadding === null || item.BottomPadding === true) ? true : false}
                                displayNumbers={(item.DisplayNumbers === null || item.DisplayNumbers === true) ? true : false}
                            ></Comparison>
                        )

                    case "page-components.ratings":
                        return (

                            <Reviews key={index}
                                text={item.Text}
                                ratingValue={item.RatingValue}
                                ratingCount={item.RatingCount}
                                reviewCount={item.ReviewCount}
                                ratingDisclaimer={item.RatingDisclaimer}
                                enableRichSnippet={item.EnableRichSnippet}
                                starImage={GetImgUrl(item.StarImage?.localFile___NODE, pageContext.images).gatsbyImageData}
                                reviewerLogo={GetImgUrl(item.ReviewerLogo?.localFile___NODE, pageContext.images).gatsbyImageData}
                            ></Reviews>

                        )


                    case "page-components.banner":
                        return (
                            <Banner
                                key={index}
                                title={checkPromoValue(item.Title, pageContext.promoInfo)}
                                subtitle={checkPromoValue(item.Subtitle, pageContext.promoInfo)}
                                text={checkPromoValue(item.Text, pageContext.promoInfo)}
                                image={item.Image != null && item.Text.includes("[*promoimage*]") ?
                                    GetImgUrl(pageContext.settingsImage, pageContext.images) :
                                    item.Image != null ?
                                        GetImgUrl(item.Image?.localFile___NODE, pageContext.images) :
                                        item.Text.includes("[*promoimage*]") ?
                                            GetImgUrl(pageContext.settingsImage, pageContext.images)
                                            : null}
                                imagePosition={item.ImagePosition}
                                dark={item.DarkBackground}
                                topPadding={(item.TopPadding === null || item.TopPadding === true) ? true : false}
                                bottomPadding={(item.BottomPadding === null || item.BottomPadding === true) ? true : false}
                                sticky={(item.Sticky === null || item.Sticky === false) ? false : true}
                                mobileStickiness={(item.MobileStickiness === null || item.MobileStickiness === false) ? false : true}
                            ></Banner>
                        )

                    case "page-components.related-articles":
                        var articlesList = []

                        if (item.articles.length == 0) {
                            pageContext.articles.slice(0, 3).map((item, index) => {
                                articlesList.push({
                                    Title: item.node.Title,
                                    slug: item.node.slug,
                                    FeaturedImage: item.node.FeaturedImage,
                                    CategorySlug: item.node.blog_category.CategorySlug,
                                    CategoryName: item.node.blog_category.CategoryName,
                                    Excerpt: item.node.Excerpt,
                                    blog_category: item.node.blog_category.CategoryName
                                })
                                return ("")
                            })
                        }


                        return (
                            <RelatedArticles
                                title={item.Title}
                                layout={item.Layout}
                                ctaLabel={item.CTALabel ? item.CTALabel : ""}
                                ctaUrl={item.CTAUrl ? item.CTAUrl : ""}
                                articles={item.articles.length != 0 ? item.articles : articlesList}
                                context={pageContext.images}

                            />

                        )




                    case "page-components.fa-qs":
                        return (
                            <Accordion
                                key={index}
                                title={item.Title ? item.Title : ""}
                                contents={item.QuestionAnswer}
                                enableSnippet={(item.EnableSnippet === null || item.EnableSnippet === false) ? false : true}
                                topPadding={(item.TopPadding === null || item.TopPadding === true) ? true : false}
                                bottomPadding={(item.BottomPadding === null || item.BottomPadding === true) ? true : false}
                            ></Accordion>
                        )

                    case "page-components.providers":
                        var ImageList = []
                        item.Images.map((imgItem, index) => {
                            ImageList.push(GetImgUrl(imgItem.localFile___NODE, pageContext.images))
                        })
                        return (
                            <Providers
                                key={index}
                                text={item.Title}
                                image={ImageList}
                                topPadding={(item.TopPadding === null || item.TopPadding === true) ? true : false}
                                bottomPadding={(item.BottomPadding === null || item.BottomPadding === true) ? true : false} />
                        )

                    case "page-components.cta-list":
                        var contentsList = []
                        item.CTAList.map((CTAitem, index) => {
                            var imageUrl = CTAitem.Image !== null ? GetImgUrl(CTAitem.Image.localFile___NODE, pageContext.images) : ""
                            contentsList.push({
                                Title: CTAitem.Title,
                                URL: CTAitem.URL,
                                Image: imageUrl,
                                Alt: CTAitem.Image.alternativeText,
                                Description: CTAitem.Description
                            })
                            return ("")
                        })
                        return (
                            <CTAList
                                key={index}
                                title={item.Title ? item.Title : ""}
                                subtitle={item.Subtitle}
                                contents={contentsList}
                                cardStyle={item.CardStyle}
                                topPadding={(item.TopPadding === null || item.TopPadding === true) ? true : false}
                                bottomPadding={(item.BottomPadding === null || item.BottomPadding === true) ? true : false}
                            ></CTAList>
                        )

                    case "page-components.cta-banner":
                        return (

                            <CTABanner
                                key={index}
                                text={checkPromoValue(item.Text, pageContext.promoInfo)}
                                ctaUrl={item.CTAUrl}
                                ctaLabel={item.CTALabel}
                                secondaryCtaUrl={item.SecondaryCTAUrl}
                                secondaryCtaLabel={item.SecondaryCTALabel}
                                bgColorHex={item.BackgroundColorHEX}
                                topPadding={(item.TopPadding === null || item.TopPadding === true) ? true : false}
                                bottomPadding={(item.BottomPadding === null || item.BottomPadding === true) ? true : false}
                            ></CTABanner>

                        )

                    case "page-components.paragraph-column":

                        return (
                            <ParagraphColumn
                                title={item.Title}
                                bgColor={item.BackgroundColor}
                                paragraphLeft={item.ParagraphLeft}
                                imageLeft={item.ParagraphLeft && item.ParagraphLeft.Image !== null ? GetImgUrl(item.ParagraphLeft.Image.localFile___NODE, pageContext.images) : ""}
                                paragraphRight={item.ParagraphRight}
                                imageRight={item.ParagraphRight && item.ParagraphRight.Image !== null ? GetImgUrl(item.ParagraphRight.Image.localFile___NODE, pageContext.images) : ""}
                            />
                        )

                    case "page-components.html-editor":
                        return (

                            <HtmlEditor
                                key={index}
                                htmlContent={item.HTML}
                                fullWidth={item.FullWidth}
                                topPadding={(item.TopPadding === null || item.TopPadding === true) ? true : false}
                                bottomPadding={(item.BottomPadding === null || item.BottomPadding === true) ? true : false}
                            ></HtmlEditor>


                        )
                    case "page-components.providers-carousel":
                        var Images = []
                        item.ImageList.map((imgItem, index) => {
                            Images.push(GetImgUrl(imgItem.Image.localFile___NODE, pageContext.images))
                        })
                        return (

                            <ProviderCarousel key={index}
                                images={Images}
                                title={item.Title}
                                subtitle={item.Subtitle}
                                ctaUrl={item.CTAUrl}
                                ctaLabel={item.CTALabel}
                                backgroundColor={item.BackgroundColor}
                                enableMask={item.EnableMask}
                                imagesWhiteBg={item.ImagesWhiteBg}
                                imageType={item.ImageType}
                            ></ProviderCarousel>


                        )

                    case "page-components.link-cards":
                        return (
                            <LinkCards
                                key={index}
                                title={item.Title}
                                bgColor={item.BackgroundColor}
                                cards={item.Cards}
                            />
                        )

                    default:
                        return ("")
                }



            })}


        </Layout>
    )

}


export default Articles